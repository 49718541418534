export const mainTheme = {
  pallette: {
    main: "red",
    black: {
      main: "#000000",
    },
    silver: {
      main: "#F0F2F8",
      light: "#4D536B",
      primary: "#6B7496",
      secondary: "#C6CBE2",
      dark: "#D8DCEB",
      text: "#667085",
      disabled: "#BED1D5",
      disabledText: "#707998",
    },
    dark: {
      main: "#101828",
    },
    blue: {
      main: "#0A1338",
      primary: "#182353",
      light: "#40a9ff",
      secondary: "#3B5998",
      link: "#00AEEF",
      border: "#1877F2",
      top: "#C5FFFD",
    },
    white: {
      main: "#FFFFFF",
      primary: "#fafafa",
      light: "#f1fcff",
    },
    green: {
      main: "#00A560",
      primary: "#77B747",
      light: "#47FE75",
      secondary: "#18a43b",
      disabled: "#C9FFD6",
      chip: "#cdffcd",
      circle: "#007f00",
      dark: "#148757",
    },
    red: {
      main: "#EF4444",
      primary: "#EF4444",
      secondary: "#E22C2A",
      error: "#7F1D1D",
      light: "#fca5a5",
      border: "#ff4d4f",
      danger: "#ff775d",
    },
    orange: {
      main: "#ff6347",
    },
    violet: {
      light: "#E9D7FE",
      main: "#6941C6",
    },
    yellow: {
      main: "#a17c0b",
      secondary: "#FDF7E7",
      light: "#fffaeb",
      border: "#FBBC04",
    },
  },
  fontSizes: [12, 14, 16, 18, 24, 32, 36, 72, 96],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
};
