export const START_FULL_STATUS = [
  "configFull",
  "checkDuringFull",
  "finishFull",
];

export const fullTitleType = (type) => {
  const titleConfig = {
    Remigration: "Remigration",
    Recent: "Recent Data Migration",
    checkFull: "Full migration check",
    configFull: "Full migration configuration",
    checkDuringFull: "Full migration check",
    finishFull: "Migration details information",
  };

  return type ? titleConfig[type] : "";
};

export const updateDataInsurance = (insurances) => {
  const insurance = insurances?.map((item) => ({
    days_new: item?.insurance_period,
    days_old: item?.old_insurance_period,
    price_percent: item?.price_percent,
    name: `${item?.number_migrations} remigration`,
    key: item?.id,
    status: item?.status,
    default: item?.default,
  }));

  if (insurance?.length) {
    return [
      ...insurance,
      {
        days_new: "",
        days_old: "",
        price_percent: "",
        name: "Skip remigration insurance",
        key: 55,
      },
    ];
  }

  return [];
};

export const migrationFullData = {
  id: 211212,
  end_insurance: "2021-05-20",
  remigration_count: 2,
  source_store_url:
    "http://centos6-httpd22-php70-mysql57.installer.magneticone.com/er/d",
  target_store_url:
    "http://centos6-httpd22-php70-mysql57.installer.magneticone.com/rer/d",
  migration_status: "Full migration completed",
  files_status: "Completed",
  total_entities_count: "9",
  total_entities_currency: "29.00",
  sub_total: "313.00",
  insurance_percent: "9.00",
  insurance_currency: "28.17",
  discount: "0.00",
  total: "341.17",
  additional_options: "0.00",
};

export const updateOptions = (
  options,
  optionData,
  dataConfig,
  requiredPlugins,
) => {
  const items = optionData.map((item) => ({
    key: item?.key,
    name: item?.name,
    price: item?.dollars || 0,
    recommended: false,
    videos_url: "",
    tooltipText: item?.icon_notice,
    required_options: options?.[item?.key]?.required_options || [],
    disabled: options?.[item?.key]?.disabled || false,
    checked: options?.[item?.key]?.checked,
    plugin: requiredPlugins?.[item?.key],
  }));

  const allOptionsFree = items?.every((items) => items.price === 0);

  if (
    items &&
    !allOptionsFree &&
    items.some((item) =>
      dataConfig?.payload?.wizard?.all_options_option?.options?.includes(
        item.key,
      ),
    )
  ) {
    items.unshift({
      key: "MIGRATION_OPTION_USER_ALL_OPTIONS",
      name: `Include all paid options and save ${dataConfig?.payload?.wizard?.all_options_option?.percent}%`,
      price: 0,
      recommended: false,
      videos_url: "",
      tooltipText: "",
      required_options:
        dataConfig?.payload?.wizard?.all_options_option?.options?.filter(
          (option) => items.some((item) => item.key === option),
        ) || [],
      disabled: options?.MIGRATION_OPTION_USER_ALL_OPTIONS?.disabled || false,
      checked: options?.MIGRATION_OPTION_USER_ALL_OPTIONS?.checked || false,
      plugin: false,
    });
  }

  return items;
};

export const defaultTotals = {
  sub_total: 0,
  insurance_percent: 0,
  insurance_currency: 0,
  discount: 0,
  total: 0,
};

export const migrationTotalByInsurance = (
  selectedRowId,
  insuranceId,
  discountId,
  isLoyalty,
) => {
  const discountLabel = isLoyalty ? "Loyalty Discount" : "Discount";

  if (selectedRowId === 55 && !discountId) {
    return ["Subtotal", "Total price"];
  }

  if (selectedRowId === 55 && discountId) {
    return ["Subtotal", discountLabel, "Total price"];
  }

  if (insuranceId && discountId) {
    return ["Subtotal", "Migration insurance", discountLabel, "Total price"];
  }

  if (!insuranceId && discountId) {
    return ["Subtotal", discountLabel, "Total price"];
  }

  if (insuranceId && !discountId) {
    return ["Subtotal", "Migration insurance", "Total price"];
  }

  return ["Subtotal", "Migration insurance", "Total price"];
};

export const START_FULL_ERROR = "This migration is already in progress!";

export const START_FULL_ERROR_PAYMENT_REQUIRED = "Payment required";

export const DATA_MIGRATION_PACKAGES_LINK =
  "https://www.shopping-cart-migration.com/provided-services/data-migration-service-packages";

export const getEntitiesArray = (entities) =>
  entities?.map((item) => ({ text: item.type }));

export const getTotals = (insuranceId, discountId, isLoyalty) => {
  const discountLabel = isLoyalty ? "Loyalty Discount" : "Discount";

  if (insuranceId && discountId) {
    return ["Subtotal", "Migration insurance", discountLabel, "Total price"];
  }

  if (insuranceId && !discountId) {
    return ["Subtotal", "Migration insurance", "Total price"];
  }

  if (discountId && !insuranceId) {
    return ["Subtotal", discountLabel, "Total price"];
  }

  return ["Subtotal", "Total price"];
};

export const hideSystemEntities = (entities) =>
  entities.filter((entity) => entity.migrate);

export const demoAlreadyDone = (status) =>
  status?.toLowerCase().includes("demo");

export const migrationTotalPriceInsurances = (totalPrice, persent) =>
  Math.round((+totalPrice / 100) * persent * 100) / 100;

export const roundingTotal = (number) => parseFloat(number).toFixed(2);

export const FULL_CONFIRM_VALUE = [
  {
    label: "Yes, launch my migration",
    value: "yes",
  },
  {
    label: "No, it’s a mistake",
    value: "no",
  },
  {
    label: "Chat with the expert",
    value: "chat",
  },
];
