import React, { useMemo } from "react";

import { useAdminPermission } from "../../../hooks/useAdminPermission";
import { useFormTo } from "../../../hooks/useFormTo";
import { userLinksByPermissions } from "../../../constants/header-user-link";
import { useLoyaltyPermission } from "../../../hooks/useLoyaltyPermission";
import { ReactComponent as StartIcon } from "../../../assets/images/StartIcon.svg";

import DropdownMenu from "../../UI/DropdownMenu";

import { Container, StyledLink, StyledButton, Wrapper } from "./styles";

const UserNavigation = () => {
  const { prefix } = useFormTo();
  const isAdmin = useAdminPermission();
  const isLoyalty = useLoyaltyPermission();

  const links = useMemo(
    () => userLinksByPermissions(isAdmin, isLoyalty, prefix),
    [isAdmin, isLoyalty, prefix],
  );

  return (
    <Container>
      <Wrapper>
        {links.map(({ title, items, to }) =>
          !items ? (
            <StyledLink key={title} to={to}>
              {title}
            </StyledLink>
          ) : (
            <DropdownMenu
              key={title}
              placement="bottom"
              title={title}
              items={items}
              to={to}
            />
          ),
        )}
      </Wrapper>

      {!isAdmin && (
        <StyledButton key="Start Here" to="/start-here">
          <StartIcon />
          Start Here
        </StyledButton>
      )}
    </Container>
  );
};

export default UserNavigation;
