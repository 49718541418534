import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useFormTo } from "../../../hooks/useFormTo";
import { useLogoutMutation } from "../../../store/authentication/authService";
import { setUserLogout } from "../../../store/profile/profileSlice";
import { setShowDrawer } from "../../../store/authentication/authSlice";
import { selectUser } from "../../../store/profile/profileService";

import { ReactComponent as LogoutIcon } from "../../../assets/images/LogoutIcon.svg";
import { userLinksByPermissions } from "../../../constants/header-user-link";
import { getProfileLinks } from "../../../constants/header-profile-link";
import { ADMIN_PERMISSIONS } from "../../../constants/permissions";
import { hasRequiredPermissions } from "../../../utils";
import { useLoyaltyPermission } from "../../../hooks/useLoyaltyPermission";

import HeaderAccordion from "../HeaderAccordion";
import ProgressProfile from "../../UserMenuDropdown/ProgressProfile";

import {
  Container,
  Wrapper,
  SubTitle,
  Title,
  LogoutButton,
  Text,
  StyledButton,
} from "./styles";

const UserDrawerContent = () => {
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prefix } = useFormTo();
  const isLoyalty = useLoyaltyPermission();

  const isAdmin = useMemo(
    () => hasRequiredPermissions(user?.abilities, ADMIN_PERMISSIONS),
    [user?.abilities],
  );
  const profileLinks = useMemo(() => getProfileLinks(prefix), []);
  const links = useMemo(
    () => userLinksByPermissions(isAdmin, isLoyalty, prefix),
    [isAdmin, isLoyalty, prefix],
  );

  const [logout] = useLogoutMutation();

  const onLogout = async () => {
    try {
      await logout().unwrap();

      dispatch(setUserLogout());
      navigate(`${prefix}/`);
    } catch (error) {
      navigate(`${prefix}/`);
    }
  };

  const onClickLink = () => dispatch(setShowDrawer(false));

  const onStartHereButtonClick = () => {
    navigate("/start-here");
    onClickLink();
  };

  return (
    <Container>
      <Wrapper border={1}>
        <Title>{user?.username}</Title>

        <SubTitle>{user?.email}</SubTitle>
      </Wrapper>

      <ProgressProfile
        percent={user?.profile_completeness}
        balance={user?.balance}
      />

      <HeaderAccordion
        items={profileLinks.concat(links)}
        onClick={onClickLink}
      />

      {!isAdmin && (
        <StyledButton onClick={onStartHereButtonClick}>Start Here</StyledButton>
      )}

      <LogoutButton onClick={onLogout}>
        <LogoutIcon />

        <Text>Logout</Text>
      </LogoutButton>
    </Container>
  );
};

export default UserDrawerContent;
