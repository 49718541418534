import React, { useCallback, useEffect } from "react";
import {
  Outlet,
  useParams,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { useFormTo } from "../../hooks/useFormTo";
import { useValidateCartPairMutation } from "../../store/wizardCreate/wizardCreateService";
import {
  setFromTo,
  setFromToParam,
} from "../../store/wizardCreate/wizardCreateSlice";

import { getStoreFromRoute } from "../../utils";
import { PATTERN_FROM_TO_PARAMS } from "../../constants/params";
import { AUTH_PATH } from "../../router/path/auth";

import LoadingModal from "../../components/LoadingModal";

const FromToProvider = () => {
  const { fromTo } = useParams();
  const { user } = useAuth();
  const { pathname, hash } = useLocation();
  const { prefix } = useFormTo();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [validatePair, { isLoading }] = useValidateCartPairMutation();
  const handleValidation = useCallback(
    async (values) => {
      try {
        const res = await validatePair({
          ...values,
        }).unwrap();

        const payload = res?.payload;

        if (payload && typeof payload === "object") {
          const stores = {
            source: "",
            target: "",
            preview: false,
          };

          Object.keys(payload).forEach((key) => {
            const value = payload[key];
            if (value) {
              stores[key] = values?.[key];
            }
          });

          dispatch(setFromTo(stores));
          dispatch(setFromToParam({ prefix: fromTo ?? prefix }));
        }
      } catch (error) {
        navigate("*");
      }
    },
    [fromTo],
  );

  const fromToRules = (param) => {
    if (PATTERN_FROM_TO_PARAMS.test(param)) {
      if (user?.id) {
        handleValidation({
          source: getStoreFromRoute(
            param?.match(PATTERN_FROM_TO_PARAMS),
            "source",
          ),
          target: getStoreFromRoute(
            param?.match(PATTERN_FROM_TO_PARAMS),
            "target",
          ),
          preview: getStoreFromRoute(
            param?.match(PATTERN_FROM_TO_PARAMS),
            "preview",
          ),
        });
      } else {
        dispatch(
          setFromToParam({ prefix: param, route: `${pathname}${hash}` }),
        );
        <Navigate to={AUTH_PATH.LOGIN} replace />;
      }
    } else if (!user?.id) {
      dispatch(setFromToParam({ prefix: param, route: `${pathname}${hash}` }));
      <Navigate to={AUTH_PATH.LOGIN} replace />;
    }
  };

  useEffect(() => {
    fromToRules(fromTo ?? prefix);
  }, [user?.id]);

  return (
    <>
      <LoadingModal isLoading={isLoading} />

      <Outlet />
    </>
  );
};

export default FromToProvider;
