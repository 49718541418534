/* eslint-disable no-nested-ternary */
import React from "react";
import { Link } from "react-router-dom";

import { ROOT } from "../router/path/auth";
import { ADMIN_PATH } from "../router/path/admin";
import { USER_PATH } from "../router/path/user";
import { MIGRATIONS_PATH } from "../router/path/migrations";

const getHeaderItems = (dataArray, prefix) =>
  dataArray.map((item) => {
    if (item.children !== undefined) {
      return (item.children || []).map((child) => ({
        key: child.key,
        label: (
          <Link className="headerLink" to={`${prefix}${child.to}`}>
            <span>{child.title}</span>
          </Link>
        ),
      }));
    }

    return {
      key: item.key,
      label: (
        <Link className="headerLink" to={`${prefix}${item.to}`}>
          <span>{item.title}</span>
        </Link>
      ),
    };
  });

const adminLinkMigration = [
  {
    key: 1,
    to: "/admin/migrations-issues",
    title: "Migrations with Issues",
  },
  {
    key: 2,
    to: "/admin/instances",
    title: "Instances",
  },
  {
    key: 3,
    to: "/admin/migrations",
    title: "View",
  },
  {
    key: 4,
    to: "/admin/dashboard",
    title: "Dashboard",
  },
  {
    key: 5,
    to: "/admin/journal",
    title: "Journal",
  },
  {
    key: 6,
    to: "/wizard/create",
    title: "Create",
  },
];

const adminLinkAccounts = [
  {
    key: 1,
    to: "/admin/accounts",
    title: "Accounts",
  },
  {
    key: 2,
    to: "/admin/accounts/accounts-balance-history",
    title: "Balance History",
  },
  {
    key: 3,
    to: "/admin/coupons",
    title: "Coupons",
  },
  {
    key: 4,
    to: "/admin/accounts-roles",
    title: "Roles",
  },
  {
    key: 5,
    to: "/admin/accounts_groups",
    title: "Groups",
  },
  {
    key: 6,
    to: "/admin/accounts/estimates",
    title: "Estimates",
  },
  {
    key: 7,
    to: "/admin/accounts-messages",
    title: "Queue",
  },
  {
    key: 8,
    to: "/admin/permissions",
    title: "Permissions",
  },
  {
    key: 9,
    to: "/admin/reports/user-feedbacks",
    title: "Feedbacks",
  },
];

const adminLinkReports = [
  {
    key: 1,
    to: "/admin/reports/coupons",
    title: "Coupons",
  },
  {
    key: 2,
    to: "/admin/reports/logs",
    title: "Logs",
  },
  {
    key: 3,
    to: "/admin/logs",
    title: "App Logs",
  },
  {
    key: 4,
    to: "/admin/reports/wizardlogs",
    title: "Wizard Logs",
  },
  {
    key: 5,
    to: "admin/reports/wizardlogs-statistics",
    title: "Wizard Logs Statistics",
  },
  {
    key: 6,
    to: "/admin/reports/error-statistics",
    title: "Error Statistics",
  },
  {
    key: 7,
    to: "/admin/reports/migrations-rates",
    title: "Demo Migrations Rates",
  },
  {
    key: 8,
    to: "/admin/reports/unsubscribers",
    title: "View Unsubscribes",
  },
  {
    key: 9,
    to: "/admin/reports/referral-url-statistics",
    title: "Referral Url Statistics",
  },
  {
    key: 10,
    to: "/admin/reports/wizard-steps-funnel",
    title: "Wizard Steps Funnel",
  },
  {
    key: 11,
    to: "/admin/reports/services-report",
    title: "Services Report",
  },
  {
    key: 12,
    to: "/admin/reports/migrations-services",
    title: "Options | Insurances | Recent Data",
  },
  {
    key: 13,
    to: "/admin/debug-statistics",
    title: "Debug Statistics",
  },
  {
    key: 14,
    to: "/admin/reports/proxies-statistics",
    title: "Proxies Statistics",
  },
  {
    key: 15,
    to: "/admin/reports/protractor-statistics",
    title: "Protractor Statistics",
  },
  {
    key: 16,
    to: "/admin/reports/weekly-marketing",
    title: "Weekly Marketing Report",
  },
  {
    key: 17,
    to: "/admin/reports/wizard-stats",
    title: "Wizard Stats",
  },
  {
    key: 18,
    to: "/admin/reports/quality",
    title: "Quality",
  },
  {
    key: 19,
    to: "/admin/reports/tickets-survey",
    title: "Tickets survey",
  },
  {
    key: 20,
    to: "/admin/reports/remigration-statistics",
    title: "ReMigration stats",
  },
];

export const adminLinkConfiguration = [
  {
    key: 1,
    to: "/configuration",
    title: "Configuration",
  },
  {
    key: 2,
    to: "/admin/price-rules",
    title: "Price Rules",
  },
  {
    key: 3,
    to: "/estimation/price-multipliers",
    title: "Estimator Price Multipliers",
  },
  {
    key: 4,
    to: "/admin/configuration/currencies",
    title: "Currencies",
  },
  {
    key: 5,
    to: "/admin/configuration/translations",
    title: "Translations",
  },
  {
    key: 6,
    to: "/admin/price-rules/packages",
    title: "Packages",
  },
  {
    key: 7,
    to: "/admin/configuration/migration-insurances",
    title: "Migration Insurances",
  },
  {
    key: 8,
    to: "/admin/configuration/entities-packs",
    title: "Entities Packs",
  },
  {
    key: 9,
    to: "/admin/supported-entities",
    title: "Supported Entities",
  },
  {
    key: 10,
    to: "/admin/configuration/cart-pair-seo-texts",
    title: "Carts SEO Texts",
  },
  {
    key: 11,
    to: "/admin/migrations-surveys",
    title: "Migrations Surveys",
  },
  {
    key: 12,
    to: "/admin/configuration/cart-upgrade-seo-texts",
    title: "Carts Upgrade SEO Texts",
  },
  {
    key: 13,
    to: "/admin/smart-mapping",
    title: "Smart Mapping",
  },
  {
    key: 14,
    to: "/admin/configuration/registration-add-funds",
    title: "Registration Add Funds",
  },
  {
    key: 15,
    to: "/admin/configuration/migration-block-config",
    title: "Migration Block",
  },
  {
    key: 16,
    to: "/admin/migrations-quality",
    title: "Migrations Quality",
  },
  {
    key: 17,
    to: "/admin/migrations-starter",
    title: "Migrations Starter",
  },
];

export const adminLinkCartPreview = [
  {
    key: 1,
    to: "/admin/cart-preview/stores",
    title: "Stores",
  },
  {
    key: 2,
    to: "/admin/cart-preview/prepared-stores",
    title: "Prepared stores",
  },
  {
    key: 3,
    to: "/admin/cart-preview/configuration",
    title: "Configuration",
  },
  {
    key: 4,
    to: "/admin/cart-preview/logs",
    title: "Logs",
  },
  {
    key: 5,
    to: "/admin/cart-preview/report",
    title: "Report",
  },
  {
    key: 6,
    to: "efefef",
    type: "group",
    title: "Dev:",
    children: [
      {
        key: "6-1",
        title: "New Cart",
        to: "/admin/new-cart",
      },
      {
        key: "6-2",
        title: "Restore Backups",
        to: "/admin/backups",
      },
    ],
  },
];

export const linkCreateMigration = [
  {
    key: "1",
    to: "/wizard/create",
    title: "Create new Migration",
  },
  {
    key: "2",
    to: "/support-service/package",
    title: "Purchase All-in-one Service Package",
  },
  // {
  //   key: "3",
  //   to: "/wizard/select-source/create#File2Cart-Shopify",
  //   title: "Migrate from CSV file",
  // },
];

export const linkPayments = [
  {
    key: "1",
    to: "/profile#balance-history",
    title: "View the list of your Balance history",
  },
  {
    key: "2",
    to: "/profile#billing",
    title: "Add Funds",
  },
  {
    key: "3",
    to: "/profile#generate-invoice",
    title: "Generate invoice for your payment",
  },
];

// export const linkPayments = (prefix) => [
//   {
//     key: "1",
//     label: (
//       <Link className="headerLink" to={`${prefix}/profile#balance-history`}>
//         View the list of your Balance history
//       </Link>
//     ),
//   },
//   {
//     key: "2",
//     label: (
//       <Link className="headerLink" to={`${prefix}/profile#billing`}>
//         Add Funds
//       </Link>
//     ),
//   },
//   {
//     key: "3",
//     label: (
//       <Link className="headerLink" to={`${prefix}/profile#generate-invoice`}>
//         Generate invoice for your payment
//       </Link>
//     ),
//   },
// ];

const adminLink = (prefix) => [
  {
    title: "Migrations",
    to: `${prefix}/${ROOT}`,
    items: getHeaderItems(adminLinkMigration, prefix),
  },
  {
    title: "Accounts",
    to: `${prefix}/${ADMIN_PATH.ACCOUNTS}`,
    items: getHeaderItems(adminLinkAccounts, prefix),
  },
  {
    title: "Reports",
    to: `${prefix}/${ADMIN_PATH.ADMIN_REPORTS}`,
    items: getHeaderItems(adminLinkReports, prefix),
  },
  {
    title: "Configuration",
    to: `${prefix}/${ADMIN_PATH.CONFIGURATION}`,
    items: getHeaderItems(adminLinkConfiguration, prefix),
  },
  {
    title: "Cart Preview",
    to: `${prefix}/${ADMIN_PATH.ADMIN_CART_STORES}`,
    items: getHeaderItems(adminLinkCartPreview, prefix),
  },
];

const userLink = (prefix) => [
  {
    title: "Create Migration",
    to: `${prefix}/${USER_PATH.WIZARD_CREATE_MIGRATION}`,
    items: getHeaderItems(linkCreateMigration, prefix),
  },

  {
    title: "My Migrations",
    to: `${prefix}/${MIGRATIONS_PATH.MY_MIGRATIONS_LIST}`,
  },

  {
    title: "My Payments",
    to: `${prefix}/${USER_PATH.PROFILE_BALANCE_HISTORY}`,
    items: getHeaderItems(linkPayments, prefix),
  },
];

const loyaltyUserLink = (prefix) => [
  {
    title: "Create Migration",
    to: `${prefix}/${USER_PATH.WIZARD_CREATE_MIGRATION}`,
    items: getHeaderItems(linkCreateMigration, prefix),
  },

  {
    title: "Accounts",
    to: `${prefix}/${USER_PATH.LOYALTY_USERS}`,
  },

  {
    title: "My Migrations",
    to: `${prefix}/${MIGRATIONS_PATH.MY_MIGRATIONS_LIST}`,
  },

  {
    title: "My Payments",
    to: `${prefix}/${USER_PATH.PROFILE_BALANCE_HISTORY}`,
    items: getHeaderItems(linkPayments, prefix),
  },
];

export const userLinksByPermissions = (isPermission, isLoyalty, prefix) => {
  if (isPermission) {
    return adminLink(prefix);
  }

  if (isLoyalty) {
    return loyaltyUserLink(prefix);
  }

  return userLink(prefix);
};
