import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { defaultService } from "./default/defaultService";
import { authService } from "./authentication/authService";
import { settingsService } from "./settings/settingsService";
import { profileService } from "./profile/profileService";
import { wizardCreateService } from "./wizardCreate/wizardCreateService";
import { migrationsService } from "./migrations/migrationsService";
import { configurationService } from "./configuration/configurationService";
import { packageService } from "./package/packageService";
import { migrationSurveysService } from "./migration-surveys/migrationSurveysService";
import { priceRulesService } from "./price-rules/priceRulesService";
import { migrationInstancesService } from "./migration-instances/migrationInstancesService";
import { adminCouponsService } from "./coupons/adminCouponsService";
import { adminFilterService } from "./filter/adminFilterService";
import { wizardLogsService } from "./wizard-logs/wizardLogsService";
import { migrationsInfoService } from "./migrations-info/migrationsInfoService";
import { smartMappingService } from "./smart-mapping/smartMappingService";
import { translationsService } from "./translations/translationsService";
import { accountsService } from "./accounts/accountsService";
import { loyaltyAccountsService } from "./loyalty-accounts/loyaltyAccountsService";
import { reportsService } from "./reports/reportsService";
import { rolesService } from "./roles/rolesService";
import { permissionsService } from "./permissions/permissionsService";
import { downloadCSVService } from "./downloadCSV/downloadCSVService";
import { migrationsStarterService } from "./migrations-starter/migrationsStarterService";
import { balanceService } from "./balance-history/balanceService";
import { estimatesService } from "./estimates/estimatesService";
import { seoTextsService } from "./seo-texts/seoTextsService";
import { migrationInsurancesService } from "./migration-insurances/migrationInsurancesService";
import { entitiesPacksService } from "./entities-packs/entitiesPacksService";
import { supportedEntitiesService } from "./supported-entities/supportedEntitiesService";
import { errorsStatisticsService } from "./errors-statistics/errorsStatisticsService";
import { migrationsServicesService } from "./migrations-services/migrationsServicesService";
import { migrationMultipliersService } from "./migrations-multipliers/migrationMultipliersService";
import { dashboardService } from "./dashboard/dashboardService";
import { configPackagesService } from "./config-packages/configPackagesService";
import { cartPreviewService } from "./cart-preview/cartPreviewService";
import { registrationFundsService } from "./registration-funds/registrationFundsService";
import { billingService } from "./billing/billingService";
import { configMigrationBlockService } from "./config-block/configMigrationBlockService";
import { accessService } from "./access/accessService";
import settingsReducer from "./settings/settingsSlice";
import authReducer from "./authentication/authSlice";
import packageReducer from "./package/packageSlice";
import profileReducer from "./profile/profileSlice";
import wizardReducer from "./wizardCreate/wizardCreateSlice";
import migrationsReducer from "./migrations/migrationsSlice";
import configurationReducer from "./configuration/configurationSlice";
import typeConnectReducer from "./type-connect/typeConnectSlice";
import migrationInfoReducer from "./migrations-info/migrationsInfoSlice";
import adminFilterReducer from "./filter/adminFilterSlice";

const authPersistConfig = {
  key: "auth",
  storage,
};

const settingsPersistConfig = {
  key: "settings",
  storage,
};

const wizardTypeConnectPersistConfig = {
  key: "wizardTypeConnect",
  storage,
};

const wizardConfig = {
  key: "wizard",
  storage,
  whitelist: ["stores", "storesConnect"],
};

const profileConfig = {
  key: "profile",
  storage,
  whitelist: ["openModal", "paymentUrl"],
};

const packageConfig = {
  key: "package",
  storage,
};

const rootState = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  wizardTypeConnect: persistReducer(
    wizardTypeConnectPersistConfig,
    typeConnectReducer,
  ),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  profile: persistReducer(profileConfig, profileReducer),
  wizard: persistReducer(wizardConfig, wizardReducer),
  userMigrations: migrationsReducer,
  package: persistReducer(packageConfig, packageReducer),
  configuration: configurationReducer,
  userMigrationsInfo: migrationInfoReducer,
  filter: adminFilterReducer,
  [defaultService.reducerPath]: defaultService.reducer,
  [authService.reducerPath]: authService.reducer,
  [settingsService.reducerPath]: settingsService.reducer,
  [profileService.reducerPath]: profileService.reducer,
  [wizardCreateService.reducerPath]: wizardCreateService.reducer,
  [migrationsService.reducerPath]: migrationsService.reducer,
  [configurationService.reducerPath]: configurationService.reducer,
  [packageService.reducerPath]: packageService.reducer,
  [migrationSurveysService.reducerPath]: migrationSurveysService.reducer,
  [priceRulesService.reducerPath]: priceRulesService.reducer,
  [migrationInstancesService.reducerPath]: migrationInstancesService.reducer,
  [adminCouponsService.reducerPath]: adminCouponsService.reducer,
  [adminFilterService.reducerPath]: adminFilterService.reducer,
  [wizardLogsService.reducerPath]: wizardLogsService.reducer,
  [migrationsInfoService.reducerPath]: migrationsInfoService.reducer,
  [smartMappingService.reducerPath]: smartMappingService.reducer,
  [translationsService.reducerPath]: translationsService.reducer,
  [accountsService.reducerPath]: accountsService.reducer,
  [loyaltyAccountsService.reducerPath]: loyaltyAccountsService.reducer,
  [reportsService.reducerPath]: reportsService.reducer,
  [rolesService.reducerPath]: rolesService.reducer,
  [permissionsService.reducerPath]: permissionsService.reducer,
  [downloadCSVService.reducerPath]: downloadCSVService.reducer,
  [migrationsStarterService.reducerPath]: migrationsStarterService.reducer,
  [balanceService.reducerPath]: balanceService.reducer,
  [estimatesService.reducerPath]: estimatesService.reducer,
  [seoTextsService.reducerPath]: seoTextsService.reducer,
  [migrationInsurancesService.reducerPath]: migrationInsurancesService.reducer,
  [entitiesPacksService.reducerPath]: entitiesPacksService.reducer,
  [supportedEntitiesService.reducerPath]: supportedEntitiesService.reducer,
  [errorsStatisticsService.reducerPath]: errorsStatisticsService.reducer,
  [dashboardService.reducerPath]: dashboardService.reducer,
  [migrationsServicesService.reducerPath]: migrationsServicesService.reducer,
  [migrationMultipliersService.reducerPath]:
    migrationMultipliersService.reducer,
  [configPackagesService.reducerPath]: configPackagesService.reducer,
  [cartPreviewService.reducerPath]: cartPreviewService.reducer,
  [registrationFundsService.reducerPath]: registrationFundsService.reducer,
  [billingService.reducerPath]: billingService.reducer,
  [configMigrationBlockService.reducerPath]:
    configMigrationBlockService.reducer,
  [accessService.reducerPath]: accessService.reducer,
});

export const store = configureStore({
  reducer: rootState,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      defaultService.middleware,
      authService.middleware,
      settingsService.middleware,
      profileService.middleware,
      wizardCreateService.middleware,
      migrationsService.middleware,
      configurationService.middleware,
      packageService.middleware,
      migrationSurveysService.middleware,
      priceRulesService.middleware,
      migrationInstancesService.middleware,
      adminCouponsService.middleware,
      adminFilterService.middleware,
      wizardLogsService.middleware,
      migrationsInfoService.middleware,
      smartMappingService.middleware,
      translationsService.middleware,
      accountsService.middleware,
      loyaltyAccountsService.middleware,
      reportsService.middleware,
      rolesService.middleware,
      permissionsService.middleware,
      downloadCSVService.middleware,
      migrationsStarterService.middleware,
      balanceService.middleware,
      estimatesService.middleware,
      seoTextsService.middleware,
      migrationInsurancesService.middleware,
      entitiesPacksService.middleware,
      supportedEntitiesService.middleware,
      errorsStatisticsService.middleware,
      dashboardService.middleware,
      migrationsServicesService.middleware,
      migrationMultipliersService.middleware,
      configPackagesService.middleware,
      cartPreviewService.middleware,
      registrationFundsService.middleware,
      billingService.middleware,
      configMigrationBlockService.middleware,
      accessService.middleware,
    ),

  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store, null);

export const isFetchBaseQueryErrorType = (error) => "status" in error;
